import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import collectionsConfig from '../configs/collectionsConfig';
import NftCollectionCard from '../components/NftCollectionCard';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'src/contexts/AppContext';
import { convertFromWei } from 'src/utils/blockchainInteractor';
import MysteriosBoxFAQ from '../components/FAQ/MysteriosBoxFAQ';
import { moralisGetPrice, moralisGetXWINPrice, moralisGetMaticPrice } from '../utils/moralisInteractor';
import { moralisXWinAddress, wbnbaddress } from '../constant';
import { getTokenSymbol, getPaymentTokenDecimalsByAddress } from '../utils/helper';
import systemConfig from '../configs/systemConfig';

const NFTCollections = ({ match }) => {
  const { t } = useTranslation(['page.marketplace']);
  const history = useHistory();
  const { account, web3 } = useContext(AppContext);
  const [collectionData, setCollectionData] = useState<Nft.NftCollection[]>([]);

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    let xwinPrice = await moralisGetXWINPrice();
    let bnbPrice = (systemConfig.chainId==137 || systemConfig.chainId==80001) ? await moralisGetMaticPrice() : await moralisGetPrice(wbnbaddress) ;
        
    const filter = collectionsConfig.collection.filter((x) => x.mysteriousbox === true);
    const promises = filter.map(async (c) => {
      
      const decimals = getPaymentTokenDecimalsByAddress(c.tokenPayment);
    
      let newCollection: Nft.NftCollection = {
        name: c.symbol?.toString(),
        description: c.name,
        contractaddress: c.contractaddress,
        imageURL: c.imageURL,
        totalSupply: '0',
        maxSupply: c.maxSupply,
        tokenPayment: c.tokenPayment,
        owner: c.owner,
        costPerNFT: convertFromWei(c.costPerNFT, decimals),
        duration: c.duration.toString(),
        xwinPrice: xwinPrice,
        bnbPrice: bnbPrice,
        usdtPrice: 1,
        pid: c.pid,
        averageScore: c.averageScore,
        maxScore: c.maxScore,
        poolendblock: c.poolendblock,
        status: c.status,
      };
      return newCollection;
    });

    const tmpColls = await Promise.all(promises);
    setCollectionData(tmpColls);
  };

  return (
    <>
      <section className="container">
        <div className="row">
          <div className="spacer-double"></div>
          {collectionData?.map((card) => (
            <NftCollectionCard card={card} />
          ))}
        </div>
        <MysteriosBoxFAQ />
      </section>
    </>
  );
};

export default NFTCollections;
