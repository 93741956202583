import _, { Dictionary } from 'lodash';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import React, { useContext, useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import { Card, CardBody, Modal } from 'reactstrap';
import { toInteger } from 'lodash';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import confetti from 'canvas-confetti';
import UserContext from '../contexts/UserContext';
import ProgressLoader from '../components/ProgressLoader';
import SpinnerLoader from '../components/SpinnerLoader';
import { updateUser } from '../services/userService';
import collectionsConfig from '../configs/collectionsConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowAltCircleLeft,
  faFileImage,
  faArrowRight,
  faFlag,
  faFolderPlus,
} from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { getOriginalOwner } from '../utils/mintNFTInteractor';

import {
  isTokenApprove,
  approvePaymentToken,
  isEnoughBalance,
  convertFromWei,
} from '../utils/blockchainInteractor';

import {
  getListingInfo,
  cancelListing,
  buyToken,
  buyWithERC20,
  getCurrentPriceByKey,
  getHighestOffer,
  acceptOffer,
  extendListing,
  isAuctionExpired,
} from '../utils/newMarketPlaceInteractor';
import {
  getMetaByHashedKey,
  updateMeta,
  getNftCategoriesList,
  getRarityJson,
} from '../services/metaService';
import { getByUserTokenAddressUsername, getUserByAddress } from '../services/userService';
import {
  bnbaddress,
  CommonRoutes,
  EventHistoryType,
  ListingType,
  NftType,
  moralisXWinAddress,
  wbnbaddress,
} from '../constant';
import Accordion from '../components/Accordion';
import NftDetailFAQ from '../components/FAQ/NftDetailFAQ';
import MoreNft from '../components/MoreNft';
import MoreNftByCollection from '../components/MoreNftByCollection';
import { approveToken, checkIfApproved, isApprovedForAll } from '../utils/erc721Interactor';

import {
  delayExecution,
  getTokenIcon,
  getTokenSymbol,
  resolvePromise,
  getCheckedSumAddress,
  getAddressShort,
  getLinkShort,
  getNftType,
  convertIPFSToHTTPS,
  getPriceInUSD,
  getNetworkById,
  getERC721ContractCategoryByAddress,
  getPaymentTokenDecimalsByAddress,
  displayMessage
} from '../utils/helper';
import AuctionEnglishOffer from '../components/AuctionEnglishOffer';
import Countdown from '../components/Countdown';
import ListForm from '../components/ListForm';
import { ResponsiveContainer } from 'recharts';
import TimeSeriesLineChart from 'src/components/TimeSeriesLineChart';
import { useTranslation } from 'react-i18next';
import Table, { Datum } from 'src/components/Table';
import { $enum } from 'ts-enum-util';
import SocialMedia from 'src/components/SocialMedia';
import { AppContext } from 'src/contexts/AppContext';
import tokenConfig from 'src/configs/tokenConfig';
import Select from 'react-select';
import { moralisGetPrice, moralisGetXWINPrice, moralisGetMaticPrice, moralisGetMainEvents } from '../utils/moralisInteractor';
import { useTheme } from '../contexts/ThemeContext';
import ReportForm from '../components/ReportForm';
import RedeemNFT from '../components/RedeemNFT';
import MoveToCollection from '../components/MoveToCollection';
import systemConfig from 'src/configs/systemConfig';
const EthDater = require('ethereum-block-by-date');
import { getCollections } from 'src/services/collectionService';

const NftDetail = ({ match }) => {
  const { t } = useTranslation(['page.nft.detail', 'enum']);
  const { themeType } = useTheme();
  const [darkTheme, setDarkTheme] = useState(themeType == 'dark');
  const { state } = useContext(UserContext);
  const { account, web3 } = useContext(AppContext);
  const history = useHistory();
  const [deadlineDate, setDeadlineDate] = useState(new Date(0));
  const [nftDetails, setNftDetails] = useState<Nft.Information>();
  const [selectedEvent, setSelectedEvent] = useState('all');
  const [events, setEvents] = useState<Nft.EventHistory[]>();
  const [allEvents, setAllEvents] = useState<Nft.EventHistory[]>();
  const [offers, setOffers] = useState<Nft.OfferHistory[]>();
  const [nftCategories, setNftCategories] = useState<Nft.NftCategory[]>([]);
  const [priceHistories, setPriceHistories] = useState<Dictionary<Nft.EventHistory[]>>();
  const [token, setToken] = useState<string>(tokenConfig.default.address);
  const [prices, setPrices] = useState({
    xwin: 1,
    bnb: 1,
    usdt: 1,
  });

  const [user, setUser] = useState<User.Information>();
  const [hashedKey, setHashedKey] = useState(match.params.hashedKey);
  const [rarityJson, setRarityJson] = useState([]);
  const [rarityData, setRarityData] = useState<Record<string, string>>();
  const [isHighestBidder, setIsHighestBidder] = React.useState(false);
  const [openListModal, setOpenListModal] = useState(false);
  const [openOfferModal, setOpenOfferModal] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isApproval, setIsApproval] = useState(false);
  const [auctionExpired, setAuctionExpired] = useState(false);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [dater, setDater] = useState(new EthDater(web3));
  const [myCollection, setMyCollection] = useState<Collection.MyCollection[]>([]);
  
  const [isLoading, setIsLoading] = useState({
    loading: false,
    type: 'page',
    message: '',
  });
  const [highestOffer, setHighestOffer] = useState({
    highestPrice: 0,
    buyer: tokenConfig.default.address,
  });
  const [reportModal, setReportModal] = useState(false);
  const [redeemModal, setRedeemModal] = useState(false);
  const [moveCollModal, setMoveCollModal] = useState(false);

  const handleMoveCollModal = () => {
    setMoveCollModal(true);
  };

  const handleRedeemModal = () => {
    setRedeemModal(true);
  };

  const handleReportModal = () => {
    setReportModal(true);
  };

  useEffect(() => {
    init();
    setDarkTheme(themeType == 'dark');
  }, [hashedKey, themeType]);

  useEffect(() => {
    setHashedKey(match.params.hashedKey);
  }, [match.params.hashedKey]);

  const init = async () => {
    if (match && match.params && match.params.hashedKey) {
      const xwinPrice = await moralisGetXWINPrice();
      const bnbPrice = (systemConfig.chainId==137 || systemConfig.chainId==80001) ? await moralisGetMaticPrice() : await moralisGetPrice(wbnbaddress) ;
      if (xwinPrice && bnbPrice) {
        setPrices({
          xwin: xwinPrice,
          bnb: bnbPrice,
          usdt: 1,
        });
      }
      await fetchNFT(hashedKey);
    } else {
      toast.error(t('toast.nft-not-found'));
      await delayExecution();
      history.replace(CommonRoutes.MARKETPLACE);
    }
  };

  const fetchDeadline = async (start2, duration2) => {
    try {
      const currentDate = new Date();
      const timestamp = currentDate.getTime();
      let tmp = await dater.getDate(
        new Date(start2 * 1000), // Date, required. Any valid moment.js value: string, milliseconds, Date() object, moment() object.
        true, // Block after, optional. Search for the nearest block before or after the given date. By default true.
        false, // Refresh boundaries, optional. Recheck the latest block before request. By default false.
      );

      const endingBlock = (tmp as any).block + Math.round((duration2 as any) / systemConfig.secondPerBlock);
      const currblock = await web3.eth.getBlockNumber();
      const seconds = (endingBlock - currblock) * systemConfig.secondPerBlock;
      return new Date(timestamp + seconds * 1000);
    } catch (error) {
      console.log(error);
      return new Date();
    }
  };

  const getRarity = async (nftContract: string) => {
    let rarityData: Record<string, string> = {};
    const card = collectionsConfig.collection.find(
      (x) => x.contractaddress.toLowerCase() === nftContract.toLowerCase() && x.mysteriousbox,
    );
    if (card) {
      const data = await getRarityJson(convertIPFSToHTTPS(card.rarityURL));
      _.map(data, function (content) {
        content.attributes.forEach((t) => {
          const key = content.traittype + t.trait;
          rarityData[key] = t.chance;
        });
      });
      setRarityData(rarityData);
      setRarityJson(data);
    }
  };

  const fetchNFT = async (hashedKey) => {
    try {
      //setIsLoading({loading: true, type: "page"})
      setIsCardLoading(true);
      const { data: nft } = await getMetaByHashedKey(hashedKey);
      if (!nft.tokenID) {
        return;
      }
      const decimals = getPaymentTokenDecimalsByAddress(nft.tokenPayment || bnbaddress);
      const [collection] = await resolvePromise(getCollections(null, false));
      setMyCollection(collection?.data || []);    

      let latestAuctionDetail;
      let currentprice;
      if (nft.listingType !== ListingType.NOT_LISTED) {
        currentprice = await getCurrentPriceByKey(web3, hashedKey, decimals);
        latestAuctionDetail = await getListingInfo(web3, hashedKey);
        const isExpired = await isAuctionExpired(web3, hashedKey);
        setAuctionExpired(isExpired);
      }

      if (nft.listingType === ListingType.AUCTION || nft.listingType === ListingType.ENGLISH_AUCTION) {
        const dl = await fetchDeadline(latestAuctionDetail?.startedAt, nft.duration);
        setDeadlineDate(dl);        
      }

      await getRarity(nft.contractAddress || '');
      const origOwner = await getOriginalOwner(web3, hashedKey);
      const [nftCategoryResponse, getNftCategoryError] = await resolvePromise(
        getNftCategoriesList(),
      );
      // setIsCardLoading(false);
      if (!getNftCategoryError) {
        setNftCategories((nftCategoryResponse?.data?.data || []).filter((d) => d.priority !== 0));
      }
      

      setNftDetails({
        ...nft,
        originalOwner: origOwner,
        hashedKey,
        decimals,
        duration: latestAuctionDetail?.duration,
        startedAt: latestAuctionDetail?.startedAt,
        currentPrice: currentprice || '0', //web3.utils.fromWei(currentprice as string, "ether"),
      });
      if (nft.ownerAddress) {
        const [userProfileResponse] = await resolvePromise(
          getUserByAddress(getCheckedSumAddress(nft.ownerAddress, web3)),
        );
        setUser(userProfileResponse?.data);
      }

      const eventHistories = await moralisGetMainEvents(hashedKey);
      const uniqueAddress = [
        ...new Set(
          eventHistories
            .map((event) => {
              return [
                getCheckedSumAddress(event?.from, web3) || '',
                getCheckedSumAddress(event?.to, web3) || '',
              ];
            })
            .flat(),
        ),
      ];
      if (uniqueAddress.length > 0) {
        const [getByUserTokenAddressUsernameResponse] = await resolvePromise(
          getByUserTokenAddressUsername({ address: uniqueAddress }),
        );
        const addressUsername = getByUserTokenAddressUsernameResponse?.data || [];
        const histories = eventHistories.map((history) => {
          let data = { fromOwner: '-', toOwner: '-' };
          if (history?.from && history?.from !== tokenConfig.default.address) {
            const fromOwner = addressUsername?.find(
              (obj) => obj.address.toLowerCase() === history.from.toLowerCase(),
            );
            data.fromOwner = fromOwner?.username || history.from;
          }
          if (history?.to && history?.to !== tokenConfig.default.address) {
            const toOwner = addressUsername?.find(
              (obj) => obj.address.toLowerCase() === history.to.toLowerCase(),
            );
            data.toOwner = toOwner?.username || history.to;
          }
          return { ...history, ...data };
        });
        setEvents(histories);
        setAllEvents(histories);

        const data = histories?.filter(
          (event) =>
            [EventHistoryType.AcceptOffer, EventHistoryType.Sale, EventHistoryType.Bid].indexOf(
              event?.eventType,
            ) >= 0,
        );
        data?.forEach((event) => {
          const decimals = getPaymentTokenDecimalsByAddress(event?.tokenPayment || "")
          event.convertedPrice = Number(convertFromWei(event.price, decimals)).toFixed(3);
          event.date = Number(event.createDt);
        });

        const paymentHistories = _.groupBy(data, 'tokenPayment');
        setPriceHistories(paymentHistories);

        setToken(Object.keys(paymentHistories)[0]);
      }

      if (nft.listingType === ListingType.ENGLISH_AUCTION) {
        let hOffer = await getHighestOffer(web3, hashedKey, decimals);
        setHighestOffer({
          highestPrice: Number(hOffer?.oPrice),
          buyer: hOffer?.buyer,
        });
        setIsHighestBidder(hOffer?.buyer === account);
      }

      await checkIfApprove(nft, currentprice);
    } catch (error) {
      console.log(error);
      toast.error(t('toast.error-fetch-nft'));
      await delayExecution();
    } finally {
      setIsCardLoading(false);

      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const checkIfApprove = async (nft, currentPrice) => {
    if (account !== '' && nft?.priceType == '1') {
      const isApp = await isTokenApprove(account, web3, currentPrice?.toString(), nft.tokenPayment);
      setIsApproval(isApp);
    }
  };
  const handleAcceptOffer = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }

    try {
      if (highestOffer.buyer == bnbaddress) {
        toast.error(t('component:no-bidder'));
        return;
      }

      if (!auctionExpired) {
        toast.error(t('component:not-expired-yet'));
        return;
      }

      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:accepting-offer'),
      });
      if (nftDetails?.hashedKey) {
        const [txn, acceptError] = await resolvePromise(
          acceptOffer(account, web3, nftDetails?.hashedKey),
        );
        if (!acceptError) {
          await resolvePromise(
            updateMeta(hashedKey, {
              paymentType: null,
              listingType: ListingType.NOT_LISTED,
              price: null,
              priceType: null,
              tokenPayment: null,
              duration: null,
              reservePrice: null,
              auctionId: null,
              startedAt: null,
              endingPrice: null,
              startingPrice: null,
            }),
          );
          confetti();
          fetchNFT(hashedKey);
        }else{
          displayMessage(acceptError, t)
        }
      }
    } catch (error) {
      toast.error(t('toast.error-accept-offer'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const displayInUSD = (currentPrice) => {
    return getPriceInUSD(prices, currentPrice, nftDetails?.tokenPayment || wbnbaddress);
  };

  const unListItem = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    try {
      if (
        nftDetails?.listingType === ListingType.ENGLISH_AUCTION &&
        highestOffer.buyer !== bnbaddress
      ) {
        toast.error(t('component:cannot-cancel-listing'));
        return;
      }

      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:canceling-nft'),
      });
      if (nftDetails?.tokenID) {
        const [, unlistError] = await resolvePromise(cancelListing(account, web3, hashedKey));
        if (!unlistError) {
          await resolvePromise(
            updateMeta(hashedKey, {
              paymentType: null,
              listingType: ListingType.NOT_LISTED,
              price: null,
              priceType: null,
              tokenPayment: null,
              duration: null,
              reservePrice: null,
              auctionId: null,
              startedAt: null,
              endingPrice: null,
              startingPrice: null,
            }),
          );
          confetti();
          fetchNFT(hashedKey);
        }else{
          displayMessage(unlistError, t)
        }
      }
    } catch (error) {
      toast.error(t('toast.error-unlist-item'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const handleRedeemNFT = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    // if nft is on listing, do not proceed
    if (ListingType.NOT_LISTED !== nftDetails?.listingType) {
      toast.error(t('redeem.cannot-redeem-message'));
      return;
    }
    setRedeemModal(true);
  };

  const handleSellNFT = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    setOpenListModal(true);
  };

  const handleExtendListing = async () => {
    if (
      nftDetails?.listingType === ListingType.ENGLISH_AUCTION &&
      highestOffer.buyer !== bnbaddress
    ) {
      toast.error(t('component:cannot-extend-listing'));
      return;
    }

    setIsLoading({
      loading: true,
      type: 'page',
      message: t('component:extend-listing'),
    });

    const [listingData, extendError] = await resolvePromise(
      extendListing(account, web3, hashedKey),
    );
    if (!extendError) {
      if (listingData.listingType == '1' && nftDetails?._id) {
        await resolvePromise(
          updateMeta(nftDetails.hashedKey, {
            paymentType: nftDetails?.paymentType,
            listingType: nftDetails.listingType, //getListingTypeMapToMongo(listingInfo.listingType),
            price: null,
            priceType: nftDetails?.priceType ? nftDetails?.priceType.toString() : '',
            tokenPayment: listingData.tokenPayment,
            duration: listingData.duration, //listingInfo.duration.toString(),
            reservePrice: null,
            auctionId: listingData.id,
            startedAt: listingData.startedAt,
            endingPrice: nftDetails?.endingPrice ? nftDetails?.endingPrice.toString() : '0',
            startingPrice: nftDetails?.startingPrice ? nftDetails?.startingPrice.toString() : '0',
          }),
        );
        confetti();
        fetchNFT(hashedKey);
      } else if (listingData.listingType == '2' && nftDetails?._id) {
        await resolvePromise(
          updateMeta(nftDetails.hashedKey, {
            paymentType: nftDetails?.paymentType,
            listingType: nftDetails.listingType, //getListingTypeMapToMongo(listingInfo.listingType),
            price: nftDetails.price ? nftDetails.price.toString() : '0',
            priceType: nftDetails?.priceType ? nftDetails?.priceType.toString() : '',
            tokenPayment: listingData.tokenPayment,
            duration: listingData.duration, //listingInfo.duration.toString(),
            reservePrice: nftDetails.reservePrice ? nftDetails.reservePrice.toString() : '0',
            auctionId: listingData.id,
            startedAt: listingData.startedAt,
            endingPrice: null,
            startingPrice: null,
          }),
        );

        confetti();
        fetchNFT(hashedKey);
      }
    }else{
      displayMessage(extendError, t)
    }
    setIsLoading({ loading: false, type: 'page', message: '' });
  };

  const handleBuy = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }

    if (nftDetails?.contractAddress && nftDetails?.ownerAddress) {
      const isAllApproved = await isApprovedForAll(
        web3,
        nftDetails?.ownerAddress,
        nftDetails?.contractAddress,
      );
      let isNFTApproved = await checkIfApproved(
        web3,
        nftDetails?.tokenID,
        nftDetails?.contractAddress,
      );
      if (!isNFTApproved && !isAllApproved) {
        toast.error(t('component:not-approve-nft-ownertransfer'));
        return;
      }
    }

    const nftPrice = Number(await getCurrentPriceByKey(web3, hashedKey, nftDetails?.decimals)); // + 10000000000000 //      toInteger(await getCurrentPriceByTokenId(web3, id)) + 10000000000000,
    const validBal = await isEnoughBalance(
      account,
      web3,
      nftDetails?.tokenPayment as string,
      nftPrice.toString(),
    );
    if (!validBal) {
      toast.error(t('component:not-enough-balance'));
      return;
    }

    try {
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:buying-nft'),
      });
      let unlistError;
      if (nftDetails?.tokenID) {
        if (nftDetails?.priceType == '0') {
          [, unlistError] = await resolvePromise(buyToken(account, web3, hashedKey, nftPrice));
        } else if (nftDetails?.priceType == '1') {
          [, unlistError] = await resolvePromise(buyWithERC20(account, web3, hashedKey));
        }
      }
      if (!unlistError) {
        await resolvePromise(
          updateMeta(hashedKey, {
            paymentType: null,
            listingType: ListingType.NOT_LISTED,
            price: null,
            priceType: null,
            tokenPayment: null,
            duration: null,
            reservePrice: null,
            auctionId: null,
            startedAt: null,
            endingPrice: null,
            startingPrice: null,
          }),
        );
        confetti();
        fetchNFT(hashedKey);
      }else{
        displayMessage(unlistError, t)
      }
    } catch (error) {
      toast.error(t('toast.error-buy-nft'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const handleApprove = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    try {
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:enable-token'),
      });
      const [, unlistError] = await resolvePromise(
        approvePaymentToken(account, web3, nftDetails?.tokenPayment),
      );
      if (!unlistError) {
        setIsApproval(true);
      }else{
        displayMessage(unlistError, t)
      }
    } catch (error) {
      toast.error(t('toast.error-approve-payment-token'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const handleSetProfileImage = async () => {
    if (nftDetails?.NFTImage && user) {
      user.profileImage = nftDetails?.NFTImage;
      const { data: returnedUser } = await updateUser(user);
      toast.success(t('component:profile-updated'));
    }
  };

  const handleChangeEvent = (selected: any) => {
    const value = selected.value;
    setSelectedEvent(value);
    if (value === 'main') {
      setEvents(allEvents);
      return;
    }
    if (value === 'sales') {
      const filterEvents = allEvents?.filter(
        (x) =>
          x.eventType === EventHistoryType.Bid ||
          x.eventType === EventHistoryType.AcceptOffer ||
          x.eventType === EventHistoryType.Sale,
      );
      setEvents(filterEvents);
      return;
    } else if (value === 'mint') {
      const filterEvents = allEvents?.filter((x) => x.eventType === EventHistoryType.Mint);
      setEvents(filterEvents);
      return;
    } else if (value === 'redeem') {
      const filterEvents = allEvents?.filter((x) => x.eventType === EventHistoryType.Redeem);
      setEvents(filterEvents);
      return;
    } else {
      const filterEvents = allEvents?.filter(
        (x) =>
          x.eventType !== EventHistoryType.Bid &&
          x.eventType !== EventHistoryType.AcceptOffer &&
          x.eventType !== EventHistoryType.Sale &&
          x.eventType !== EventHistoryType.Mint,
      );
      setEvents(filterEvents);
      return;
    }
  };

  const columns = {
    offer: [
      {
        key: 'buyerUsername',
        header: t('table.trade-history.columns.from'),
        sortable: false,
        cell: ({ cell, row }): JSX.Element => {
          if (!web3.utils.isAddress(cell.value)) {
            return (
              <NavLink to={`/user/${cell.value}`} className="nav-link">
                {cell.value}
              </NavLink>
            );
          }
          return <span className="text-truncate">{cell.value}</span>;
        },
      },
      {
        key: 'offerPrice',
        flex: { basis: '100px', shrink: 1, grow: 0 },
        header: t('table.trade-history.columns.price'),
        sortable: false,
        cell: ({ cell, row }): JSX.Element =>
          cell.value && (
            <span>{`${Number(convertFromWei(cell.value, getPaymentTokenDecimalsByAddress(row.original.tokenPayment))).toFixed(3)} ${getTokenSymbol(
              nftDetails?.tokenPayment,
            )}`}</span>
          ),
      },
    ],
    trade: [
      {
        key: 'eventType',
        flex: { basis: '100px', shrink: 1, grow: 0 },
        header: t('table.trade-history.columns.event'),
        sortable: false,
        cell: ({ cell, row }): JSX.Element => (
          <span>
            {t(`enum:EventHistoryType.${$enum(EventHistoryType).getKeyOrThrow(cell.value)}`)}
          </span>
        ),
      },
      {
        key: 'fromOwner',
        header: t('table.trade-history.columns.from'),
        sortable: false,
        cell: ({ cell, row }): JSX.Element => {
          if (!web3.utils.isAddress(cell.value) && cell.value !== '-') {
            return (
              <NavLink to={`/user/${cell.value}`} className="nav-link">
                {cell.value}
              </NavLink>
            );
          }
          return <span className="text-truncate">{cell.value}</span>;
        },
      },
      {
        key: 'toOwner',
        header: t('table.trade-history.columns.to'),
        sortable: false,
        cell: ({ cell, row }): JSX.Element => {
          if (!web3.utils.isAddress(cell.value) && cell.value !== '-') {
            return (
              <NavLink to={`/user/${cell.value}`} className="nav-link">
                {cell.value}
              </NavLink>
            );
          }
          return <span className="text-truncate">{cell.value}</span>;
        },
      },
      {
        key: 'price',
        flex: { basis: '100px', shrink: 1, grow: 0 },
        header: t('table.trade-history.columns.price'),
        sortable: false,
        cell: ({ cell, row }): JSX.Element => {
          return Number(cell.value) ? (
            <span>
              <img
                src={getTokenIcon(row.original.tokenPayment)}
                className="my-3 pr-2"
                style={{ height: '1.45rem' }}
              />
              {`${Number(convertFromWei(cell.value, getPaymentTokenDecimalsByAddress(row.original.tokenPayment))).toFixed(2)}`}
            </span>
          ) : (
            <></>
          );
        },
      },
      {
        key: 'createDt',
        flex: { basis: '100px', shrink: 1, grow: 0 },
        header: t('table.trade-history.columns.date'),
        sortable: false,
        cell: ({ cell, row }): JSX.Element => (
          <span>{moment(cell.value).format('DD/MM/YYYY')}</span>
        ),
      },
    ],
  };

  const displayRarity = (att) => {
    if (rarityData == undefined) return '';
    const key = att.trait_type + att.value;
    return `${rarityData[key]}%` || '';
  };

  const rarityScore = () => {
    if (rarityData === undefined) return '';

    let totalScore = 0;
    nftDetails?.attributes?.map((attribute) => {
      const key = attribute.trait_type + attribute.value;
      if (rarityData[key]) {
        const singleTraitScore = 1 / Number(rarityData[key]);
        totalScore = totalScore + singleTraitScore;
      }
    });
    return Number(totalScore * 10).toFixed(3);
  };

  const [openMenu1, setOpenMenu1] = React.useState(false);
  const [openMenu2, setOpenMenu2] = React.useState(true);
  const [openMenu3, setOpenMenu3] = React.useState(false);

  const handleBtnClickDetail = () => {
    if (!openMenu3) {
      setOpenMenu1(false);
      setOpenMenu2(false);
      setOpenMenu3(true);

      document.getElementById('Mainbtn3')?.classList.add('active');
      document.getElementById('Mainbtn2')?.classList.remove('active');
      document.getElementById('Mainbtn1')?.classList.remove('active');
    }
  };

  const handleBtnClick1 = () => {
    if (!openMenu1) {
      setOpenMenu1(true);
      setOpenMenu2(false);
      setOpenMenu3(false);
      document.getElementById('Mainbtn2')?.classList.add('active');
      document.getElementById('Mainbtn1')?.classList.remove('active');
      document.getElementById('Mainbtn3')?.classList.remove('active');
    }
  };
  const handleBtnClick2 = () => {
    if (!openMenu2) {
      setOpenMenu1(false);
      setOpenMenu3(false);
      setOpenMenu2(true);
      document.getElementById('Mainbtn2')?.classList.remove('active');
      document.getElementById('Mainbtn3')?.classList.remove('active');
      document.getElementById('Mainbtn1')?.classList.add('active');
    }
  };

  const customStyles = {
    option: (base, state) => ({
      ...base,
      background: 'var(--background)',
      color: 'var(--text)',
      borderRadius: state.isFocused ? '0' : 0,
      '&:hover': {
        background: 'var(--text)',
        color: 'var(--background)',
      },
    }),
    menu: (base) => ({
      ...base,
      background: 'var(--background) !important',
      borderRadius: 0,
      marginTop: 0,
    }),
    menuList: (base) => ({
      ...base,
      padding: 0,
    }),
    control: (base, state) => ({
      ...base,
      padding: 2,
    }),
  };

  const getOptionList = () => {
    if (priceHistories !== undefined) {
      return Object.keys(priceHistories).map((tokenPayment) => ({
        value: tokenPayment,
        label: getTokenSymbol(tokenPayment),
      }));
    } else {
      return [{ value: 'x', label: 'y' }];
    }
  };

  const getUserCollectionName = (id) => {
    
    if(id === undefined) return "N/A";
    const selected = myCollection.find(x=>x._id === id);
    return selected?.name || "N/A"      
  }

  const displayCollection = () => {

    const colName = getUserCollectionName(nftDetails?.userCollectionId);

    if(colName !== "N/A"){
      return (
        <span
          role="button"
          onClick={() => history.push(`${CommonRoutes.COLLECTION}/${colName}`)}      
          ><p>Collection: {colName}</p></span>
      )
    }else{
      return (
        <span><p>Collection: {getUserCollectionName(nftDetails?.userCollectionId)}</p></span>
      )
    }
                
  }

  return (
    <>
      <section className="container">
        <ProgressLoader
          loading={isLoading.loading}
          type={isLoading.type}
          message={isLoading.message}
        />
        <div className="row mt-md-5 pt-md-4">
          <div className="col-md-6 text-center">
            <div className="row">
              {isCardLoading ? (
                <SpinnerLoader loading={isCardLoading} />
              ) : getNftType(nftDetails?.contentType as string) === NftType.Audio ||
                getNftType(nftDetails?.contentType as string) === NftType.Video ? (
                <ReactPlayer
                  controls
                  loop
                  playing
                  width="100%"
                  url={convertIPFSToHTTPS(nftDetails?.NFTImage || '')}
                />
              ) : (
                <span
                  role="button"
                  onClick={() =>
                    window.open(
                      convertIPFSToHTTPS(nftDetails?.NFTImage || '/assets/images/preloader.png'),
                      '_blank',
                    )
                  }
                >
                  <img
                    className="img-fluid img-rounded mb-sm-30 w-100"
                    src={convertIPFSToHTTPS(nftDetails?.NFTImage || '/assets/images/preloader.png')}
                    width="auto"
                    height="600px"
                    max-height="100%"
                  />
                </span>
              )}
            </div>
            <div className="row">
              <div className="row mt-4">
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="nft_attr">
                    <h5>{t('details.token-id')}</h5>
                    <h4>{nftDetails?.tokenID}</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="nft_attr">
                    <h5>{t('details.category')}</h5>
                    <h4>{nftDetails?.category}</h4>
                  </div>
                </div>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id="tooltip-2">{t('Click to copy')}</Tooltip>}
                >
                  <div className="col-lg-4 col-md-6 col-sm-6">
                    <CopyToClipboard
                      onCopy={() =>
                        toast('🦄 Copied!', {
                          position: 'bottom-center',
                          autoClose: 1000,
                        })
                      }
                      text={nftDetails?.contractAddress}
                    >
                      <div className="nft_attr">
                        <h5>{t('NFT Address')}</h5>
                        <h4>{getAddressShort(nftDetails?.contractAddress)}</h4>
                      </div>
                    </CopyToClipboard>
                  </div>
                </OverlayTrigger>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="nft_attr">
                    <h5>{t('details.token-standard')}</h5>
                    <h4>{'ERC721'}</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="nft_attr">
                    <h5>{t('details.token-chain')}</h5>
                    <h4>{getNetworkById(systemConfig.chainId).chainName}</h4>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="nft_attr">
                    <h5>{t('details.external-link')}</h5>
                    <h4>
                      <a href={convertIPFSToHTTPS(nftDetails?.externalLink || '')} target="_blank">
                        {getLinkShort(nftDetails?.externalLink)}
                      </a>
                    </h4>
                  </div>
                </div>
              </div>

              {nftDetails?.listingType === ListingType.AUCTION && (
                <>
                  <div className="row">
                    <hr className="my-4" />
                  </div>
                  <Accordion icon="fas fa-clipboard-list" title={t('label.auction-information')}>
                    <div className="d-flex text justify-content-between">
                      <span>{t('auction-information.start-date')}</span>
                      <span>
                        {moment(toInteger(nftDetails?.startedAt) * 1000).format(
                          'MM/DD/YYYY hh:mm:ss a',
                        )}
                      </span>
                    </div>
                    <div className="d-flex text justify-content-between">
                      <span>{t('auction-information.end-date')}</span>
                      <span>
                        {moment(toInteger(nftDetails?.startedAt) * 1000)
                          .add(Math.round(toInteger(nftDetails?.duration) / 3600), 'hours')
                          .format('MM/DD/YYYY hh:mm:ss a')}
                      </span>
                    </div>
                    <div className="d-flex text justify-content-between">
                      <span>{t('auction-information.start-price')}</span>
                      <span>{`${Number(
                        nftDetails?.startingPrice as unknown as string,
                      ).toString()} ${getTokenSymbol(nftDetails?.tokenPayment)}`}</span>
                    </div>

                    <div className="d-flex text justify-content-between">
                      <span>{t('auction-information.end-price')}</span>
                      <span>
                        {`${Number(
                          nftDetails?.endingPrice as unknown as string,
                        ).toString()} ${getTokenSymbol(nftDetails?.tokenPayment)}`}
                      </span>
                    </div>
                  </Accordion>
                </>
              )}

              {/* {(nftDetails?.attributes as Nft.NftAttribute[])?.length > 0 && (
                <>
                  <div className="row">
                    <hr className="my-4" />
                  </div>
                  <h5 className="mt-2 text-center">
                    {rarityScore() == ""
                      ? t("label.trait")
                      : t("Rarity Score of ") + rarityScore()}
                  </h5>
                  <div className="row mt-2">
                    {nftDetails?.attributes?.map((attribute) => {
                      if (attribute.trait_type !== "") {
                        return (
                          <>
                            <div className="col-lg-4 col-md-6 col-sm-6">
                              <div className="nft_attr">
                                <h5>{attribute.trait_type}</h5>
                                <h4>{attribute.value}</h4>
                                <span>
                                  {rarityData && (
                                    <span>{displayRarity(attribute)}</span>
                                  )}
                                </span>
                              </div>
                            </div>
                          </>
                        );
                      }
                      return <></>;
                    })}
                  </div>
                </>
              )} */}
            </div>
          </div>
          <div className="col-md-6">
            <div className="item_info">
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="tooltip-1">{'Back to previous page'}</Tooltip>}
              >
                <FontAwesomeIcon
                  icon={faArrowAltCircleLeft}
                  className="icon-link"
                  onClick={history.goBack}
                />
              </OverlayTrigger>
              {state?.user &&
                account &&
                getCheckedSumAddress(nftDetails?.ownerAddress, web3) ===
                  getCheckedSumAddress(state?.user?.address, web3) && (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={<Tooltip id="tooltip-2">{t('component:set-profile-image')}</Tooltip>}
                    >
                      <FontAwesomeIcon
                        icon={faFileImage}
                        className="icon-link-red"
                        onClick={handleSetProfileImage}
                      />
                    </OverlayTrigger>
                  </>
                )}

              {state?.user && account && (
                <>
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip id="tooltip-3">{'Report'}</Tooltip>}
                  >
                    <FontAwesomeIcon
                      icon={faFlag}
                      className="icon-link"
                      onClick={handleReportModal}
                    />
                  </OverlayTrigger>
                </>
              )}

            

              <h2>{nftDetails?.title}</h2>
              
              <div className="d-flex text-success justify-content-start">
                {
                  displayCollection()
                }
                <span>
                {state?.user && account && nftDetails?.originalOwner == account
                  && nftDetails.listingType == ListingType.NOT_LISTED &&  getUserCollectionName(nftDetails?.userCollectionId) === "N/A" && (
                    <>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="tooltip-3">{'Move To Collection'}</Tooltip>}
                      >
                        <FontAwesomeIcon
                          icon={faFolderPlus}
                          className="icon-link"
                          onClick={handleMoveCollModal}
                        />
                      </OverlayTrigger>
                    </>
                  )}
                </span>
              </div>
              
              <p>{nftDetails?.description}</p>
              <div className="d-flex align-items-center justify-content-between mt-4 mb-2">
                <NavLink to={`/user/${user?.username}`} className="nav-link">
                  {`${t('label.owned-by')}: `}
                  {user?.username}
                </NavLink>
                <SocialMedia user={user as User.Information} />
              </div>
              {state?.user &&
                account &&
                getCheckedSumAddress(nftDetails?.ownerAddress, web3) ===
                  getCheckedSumAddress(state?.user?.address, web3) && (
                  <>
                    {nftDetails?.listingType === ListingType.NOT_LISTED && (
                      <>
                        <div className="d-flex justify-content-right">
                          <button onClick={handleSellNFT} className="btn-sub1">
                            {t('Sell NFT')}
                            <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                          </button>
                          {nftDetails?.originalOwner !== account &&
                            getERC721ContractCategoryByAddress(
                              nftDetails.contractAddress,
                              nftCategories,
                            )?.toLowerCase() === 'services' && (
                              <button onClick={() => handleRedeemNFT()} className="btn-sub1">
                                {t('redeem.title')}
                              </button>
                            )}
                        </div>
                      </>
                    )}
                    {nftDetails?.listingType !== ListingType.NOT_LISTED && (
                      <>
                        <div className="d-flex justify-content-right">
                          <button onClick={() => unListItem()} className="btn-sub1">
                            {t('button.cancel-auction')}
                          </button>
                        </div>
                      </>
                    )}
                    {auctionExpired &&
                      (nftDetails?.listingType === ListingType.AUCTION ||
                        nftDetails?.listingType === ListingType.ENGLISH_AUCTION) && (
                        <>
                          Or
                          <div className="browse-buy-btn">
                            <button onClick={() => handleExtendListing()} className="btn-sub1">
                              {t('Extend Listing')}
                            </button>
                          </div>
                        </>
                      )}
                    {/* <>
                        <div className="browse-buy-btn">
                          <button
                            onClick={() => handleRedeemNFT()}
                            className="btn-sub1"
                          >
                            {t("redeem.title")}
                          </button>
                        </div>
                      </> */}
                  </>
                )}

              {ListingType.NOT_LISTED !== nftDetails?.listingType && (
                <Card className="my-4">
                  <CardBody className="rounded-card-body">
                    <div className="browse-bid-box">
                      {nftDetails?.listingType === ListingType.MARKETPLACE && (
                        <>
                          <h6 className="mb-0">{t('label.price')}</h6>
                          <div className="d-flex">
                            <img
                              src={getTokenIcon(nftDetails?.tokenPayment)}
                              className="my-3 pr-3"
                              style={{ height: '1.75rem' }}
                            />
                            <h3>
                              {`${nftDetails?.price as unknown as string} ${getTokenSymbol(
                                nftDetails?.tokenPayment,
                              )}`}
                            </h3>
                            ~${displayInUSD(nftDetails?.currentPrice)}
                          </div>
                          {state?.user &&
                            account &&
                            getCheckedSumAddress(nftDetails?.ownerAddress, web3) !==
                              getCheckedSumAddress(state?.user?.address, web3) && (
                              <div className="browse-buy-btn">
                                <button
                                  onClick={() => {
                                    nftDetails?.priceType == '1' && !isApproval
                                      ? handleApprove()
                                      : handleBuy();
                                  }}
                                  className="btn-sub1"
                                  disabled={!account}
                                >
                                  {nftDetails?.priceType == '1' && !isApproval && account
                                    ? t('Approve')
                                    : t('button.buy-now')}
                                </button>
                              </div>
                            )}
                        </>
                      )}
                      {nftDetails?.listingType === ListingType.AUCTION && (
                        <>
                          <h6 className="my-2">{t('label.current-price')}</h6>
                          <div className="d-flex">
                            <img
                              src={getTokenIcon(nftDetails?.tokenPayment)}
                              className="my-2 pr-3"
                              style={{ height: '1.75rem' }}
                            />
                            <h3>
                              {`${Number(nftDetails?.currentPrice as unknown as string).toFixed(
                                3,
                              )} ${getTokenSymbol(nftDetails?.tokenPayment)}`}
                            </h3>
                            ~${displayInUSD(nftDetails?.currentPrice)}
                          </div>
                          {getCheckedSumAddress(nftDetails?.ownerAddress, web3) !==
                            getCheckedSumAddress(state?.user?.address, web3) && (
                            <div className="browse-buy-btn mb-2 text-center">
                              <button
                                onClick={() => {
                                  nftDetails?.priceType == '1' && !isApproval
                                    ? handleApprove()
                                    : handleBuy();
                                }}
                                className="btn-sub1"
                              >
                                {nftDetails?.priceType == '1' && !isApproval
                                  ? t('Approve')
                                  : t('button.place-bid')}
                              </button>
                            </div>
                          )}

                          <Countdown deadline={deadlineDate} />
                        </>
                      )}
                      {nftDetails?.listingType === ListingType.ENGLISH_AUCTION && (
                        <>
                          <h6 className="mb-0">{t('label.current-price')}</h6>
                          <div className="d-flex">
                            <img
                              src={getTokenIcon(nftDetails?.tokenPayment)}
                              className="my-3 pr-3"
                              style={{ height: '1.75rem' }}
                            />
                            <h3>
                              {`${Number(highestOffer?.highestPrice).toFixed(3)} ${getTokenSymbol(
                                nftDetails?.tokenPayment,
                              )}`}
                            </h3>
                            ~${displayInUSD(nftDetails?.currentPrice)}
                          </div>
                          <h6 className="mb-0">{t('Reserve Price')}</h6>
                          <div className="d-flex">
                            <img
                              src={getTokenIcon(nftDetails?.tokenPayment)}
                              className="my-3 pr-3"
                              style={{ height: '1.75rem' }}
                            />
                            <h3>
                              {`${Number(nftDetails?.reservePrice as unknown as string).toFixed(
                                3,
                              )} ${getTokenSymbol(nftDetails?.tokenPayment)}`}
                            </h3>
                          </div>
                          {!auctionExpired &&
                            state?.user &&
                            account &&
                            getCheckedSumAddress(nftDetails?.ownerAddress, web3) !==
                              getCheckedSumAddress(state?.user?.address, web3) && (
                              <div className="browse-buy-btn mb-2 text-center">
                                <button
                                  disabled={auctionExpired || isHighestBidder || !account}
                                  onClick={() => {
                                    nftDetails?.priceType == '1' && !isApproval
                                      ? handleApprove()
                                      : setOpenOfferModal(true);
                                  }}
                                  className="btn-sub1"
                                >
                                  {nftDetails?.priceType == '1' && !isApproval
                                    ? t('Approve')
                                    : nftDetails?.isExpired
                                    ? 'Expired'
                                    : t('button.make-offer')}
                                </button>
                                {isHighestBidder && (
                                  <span className="store-label">
                                    ** {t('label.highest-bidder')}
                                  </span>
                                )}
                              </div>
                            )}
                          {state?.user &&
                            account &&
                            getCheckedSumAddress(nftDetails?.ownerAddress, web3) ===
                              getCheckedSumAddress(state?.user?.address, web3) &&
                            nftDetails?.listingType === ListingType.ENGLISH_AUCTION && (
                              <>
                                <div className="browse-buy-btn">
                                  <button
                                    // disabled={!nftDetails?.isExpired}
                                    onClick={handleAcceptOffer}
                                    className="btn-sub1"
                                  >
                                    {t('button.accept-offer')}
                                  </button>
                                </div>
                              </>
                            )}
                          <Countdown deadline={deadlineDate} />
                        </>
                      )}
                      {nftDetails?.listingType === ListingType.NOTVALID && (
                        <>
                          <div className="d-flex">
                            <img
                              src={'/assets/images/investigation.png'}
                              className="my-3 pr-2"
                              style={{ height: '2.75rem' }}
                            />
                            <h3 className="text-warning my-3">
                              {t('component:under-investigation')}
                            </h3>
                          </div>
                        </>
                      )}
                    </div>
                  </CardBody>
                </Card>
              )}

              <div>
                <div className="spacer-40"></div>

                <div className="de_tab">
                  <ul className="de_nav">
                    <li id="Mainbtn1" className="active">
                      <span onClick={handleBtnClick2}>{t('label.trade-history')}</span>
                    </li>
                    <li id="Mainbtn2">
                      <span onClick={handleBtnClick1}>{`${t(
                        'label.price-history',
                      )} (${getTokenSymbol(token)})`}</span>
                    </li>
                    <li id="Mainbtn3">
                      <span onClick={handleBtnClickDetail}>
                        {/* {t("label.nft-detail")} */}
                        {rarityScore() == ''
                          ? t('label.trait')
                          : t('Rarity Score of ') + rarityScore()}
                      </span>
                    </li>
                    {/* <li id="Mainbtn3">
                      <span onClick={handleReportModal}><i className="fa fa-flag"/>&nbsp;&nbsp;Report</span>
                    </li> */}
                  </ul>

                  <div className="de_tab_content">
                    {openMenu1 && (
                      <div className="tab-1 onStep fadeIn">
                        {(priceHistories?.[token as string] ?? []).length > 0 ? (
                          <div className="p-2">
                            <div className="items_filter d-flex justify-content-end">
                              <div className="dropdownSelect one">
                                <Select
                                  className="select1"
                                  styles={customStyles}
                                  isSearchable={false}
                                  onChange={(selected) => setToken(selected.value as string)}
                                  defaultValue={getOptionList()[0]}
                                  options={getOptionList()}
                                />
                              </div>
                            </div>
                            <ResponsiveContainer width="100%" height={250}>
                              <TimeSeriesLineChart
                                data={priceHistories?.[token as string]}
                                dataKey={{
                                  x: 'date',
                                  y: 'convertedPrice',
                                }}
                                height={250}
                                width={800}
                              />
                            </ResponsiveContainer>
                          </div>
                        ) : (
                          <>{t('label.no-price-history-data')}</>
                        )}
                      </div>
                    )}
                    {openMenu2 && (
                      <div className="tab-2 onStep fadeIn">
                        {(events ?? []).length > 0 ? (
                          <div className="p-2">
                            <div className="items_filter d-flex justify-content-end">
                              <div className="dropdownSelect one">
                                <Select
                                  className="select1"
                                  styles={customStyles}
                                  isSearchable={false}
                                  onChange={handleChangeEvent}
                                  defaultValue={{
                                    value: 'main',
                                    label: 'Main Events',
                                  }}
                                  options={[
                                    { value: 'main', label: 'Main Events' },
                                    { value: 'sales', label: 'Sales' },
                                    { value: 'mint', label: 'Mint' },
                                    { value: 'redeem', label: 'Redeem' },
                                    { value: 'other', label: 'Others' },
                                  ]}
                                />
                              </div>
                            </div>
                            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-0 offset-lg-0 offset-md-0 div-scrollable">
                              <Table columns={columns.trade} data={events as Datum[]} />
                            </div>
                          </div>
                        ) : (
                          <>{t('label.no-trade-history-data')}</>
                        )}
                      </div>
                    )}
                    {openMenu3 && (
                      <div className="tab-1 onStep fadeIn">
                        {(nftDetails?.attributes as Nft.NftAttribute[])?.length > 0 && (
                          <>
                            <div className="row">
                              <hr className="my-4" />
                            </div>
                            <div className="row mt-2">
                              {nftDetails?.attributes?.map((attribute) => {
                                if (attribute.trait_type !== '') {
                                  return (
                                    <>
                                      <div className="col-lg-4 col-md-6 col-sm-6">
                                        <div className="nft_attr">
                                          <h5>{attribute.trait_type}</h5>
                                          <h4>{attribute.value}</h4>
                                          <span>
                                            {rarityData && <span>{displayRarity(attribute)}</span>}
                                          </span>
                                        </div>
                                      </div>
                                    </>
                                  );
                                }
                                return <></>;
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer-40"></div>
        <div className="col-md-12">
          <div className="row">
          {
            getUserCollectionName(nftDetails?.userCollectionId) !== "N/A" && (
              <>
              <MoreNftByCollection 
              ownerAddress={getCheckedSumAddress(nftDetails?.ownerAddress, web3) || ''} 
              userCollectionName = {getUserCollectionName(nftDetails?.userCollectionId)}
              />
              </>
          )}  
          {getUserCollectionName(nftDetails?.userCollectionId) === "N/A" && (
              <>
              <MoreNft ownerAddress={getCheckedSumAddress(nftDetails?.ownerAddress, web3) || ''} />
              </>
          )}     
          </div>
        </div>
        <div className="spacer-40"></div>
        <NftDetailFAQ />
      </section>
      <Modal
        isOpen={openListModal}
        toggle={() => setOpenListModal(!openListModal)}
        keyboard={false}
        size="lg"
      >
        <div className="checkout">
          <div className="maincheckout">
            {/* {darkTheme ? (
              <button
                className="btn-close green"
                onClick={() => setOpenListModal(!openListModal)}
              >
                x
              </button>
            ) : (
              <button
                className="btn-close purple"
                onClick={() => setOpenListModal(!openListModal)}
              >
                x
              </button>
            )} */}

            <button
              className={`btn-close ${darkTheme ? 'green' : 'purple'}`}
              onClick={() => setOpenListModal(!openListModal)}
            >
              x
            </button>

            <ListForm
              reset={() => {
                fetchNFT(hashedKey);
                setOpenListModal(false);
              }}
              hashedKey={nftDetails?.hashedKey}
              tokenId={nftDetails?.tokenID}
              nftDetails={nftDetails}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={openOfferModal}
        toggle={() => setOpenOfferModal(!openOfferModal)}
        backdrop
        keyboard={false}
      >
        <div className="checkout">
          <div className="maincheckout">
            <button
              className={`btn-close ${darkTheme ? 'green' : 'purple'} m-1`}
              onClick={() => setOpenOfferModal(!openOfferModal)}
            >
              x
            </button>
            <AuctionEnglishOffer
              reset={() => {
                fetchNFT(hashedKey);
                setOpenOfferModal(false);
              }}
              nftDetails={nftDetails}
              highestOffer={highestOffer}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={reportModal}
        toggle={() => setReportModal(!reportModal)}
        backdrop
        keyboard={false}
      >
        <div className="checkout">
          <div className="maincheckout">
            <button
              className={`btn-close ${darkTheme ? 'green' : 'purple'} m-1`}
              onClick={() => setReportModal(!reportModal)}
            >
              x
            </button>
            <ReportForm closeForm={() => setReportModal(false)} hashedKey={hashedKey} />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={redeemModal}
        toggle={() => setRedeemModal(!redeemModal)}
        backdrop
        keyboard={false}
      >
        <div className="checkout">
          <div className="maincheckout">
            <button
              className={`btn-close ${darkTheme ? 'green' : 'purple'} m-1`}
              onClick={() => setRedeemModal(!redeemModal)}
            >
              x
            </button>
            <RedeemNFT
              reset={() => {
                fetchNFT(hashedKey);
                setRedeemModal(false);
              }}
              // closeForm={() => setRedeemModal(false)}
              hashedKey={hashedKey}
              nftDetails={nftDetails}
            />
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={moveCollModal}
        toggle={() => setMoveCollModal(!moveCollModal)}
        backdrop
        keyboard={false}
      >
        <div className="checkout">
          <div className="maincheckout">
            <button
              className={`btn-close ${darkTheme ? 'green' : 'purple'} m-1`}
              onClick={() => setMoveCollModal(!moveCollModal)}
            >
              x
            </button>
            <MoveToCollection
              reset={() => {
                fetchNFT(hashedKey);
                setMoveCollModal(false);
              }}
              // closeForm={() => setRedeemModal(false)}
              hashedKey={hashedKey}
              nftDetails={nftDetails}
              myCollection={myCollection}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NftDetail;
