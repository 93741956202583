import tokenConfig from 'src/configs/tokenConfig';
import Web3 from 'web3';
import { ListingType, NftType, xwinaddress, bnbaddress, usdtaddress } from '../constant';
import systemConfig from 'src/configs/systemConfig';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

export const resolvePromise = <T = any, U = any>(
  promise: Promise<T>,
): Promise<[T | null, U | any]> => {
  return new Promise((resolve) => {
    promise
      .then((response: any) => {
        resolve([response, null]);
      })
      .catch((error) => {
        resolve([null, error]);
      });
  });
};

export const delayExecution = async (miliSeconds = 3000): Promise<void> => {
  return new Promise<void>((resolve) => {
    setTimeout(async () => {
      resolve();
    }, miliSeconds);
  });
};

export const goToBSCScan = (address) => {
  if (address === undefined) {
    return;
  }
  const network = getNetworkById(systemConfig.chainId);
  let bscaddress = network.blockExplorerUrls + "/address/";
  window.open(bscaddress + address, '_blank');
};

export const getAddressShort = (address) => {
  if (address === undefined) {
    return '';
  }

  const right5 = address.substring(0, 4);
  const left5 = address.substring(address.length - 4, address.length);
  return `${right5}...${left5}`;
};

export const getLinkShort = (address) => {
  if (address === undefined) {
    return '';
  }

  const right8 = address.substring(0, 8);
  const left8 = address.substring(address.length - 8, address.length);
  return `${right8}...${left8}`;
};

export const getTokenSymbol = (address?: string) => {
  if (address === null) {
    return tokenConfig.default.token;
  }

  if (address === undefined) {
    return tokenConfig.default.token;
  }

  const selected = tokenConfig.paymentTokens.find(
    (t) => t.address.toLowerCase() === address.toLowerCase(),
  );

  return selected === undefined ? tokenConfig.default.token : selected?.name;
};

export const getTokenIcon = (address?: string) => {
  
  if (address === undefined) {
    return `/assets/images/tokens/${tokenConfig.default.icon}`;
  }

  const selected = tokenConfig.paymentTokens.find(
    (t) => t.address.toLowerCase() === address.toLowerCase(),
  );
  return selected === undefined
    ? `/assets/images/tokens/${tokenConfig.default.icon}`
    : `/assets/images/tokens/${selected?.icon}`;
};

export const getListingTypeMapToMongo = (listingType?: string) => {
  if (listingType === '0') {
    return ListingType.MARKETPLACE;
  }

  if (listingType === '1') {
    return ListingType.AUCTION;
  }

  if (listingType === '2') {
    return ListingType.ENGLISH_AUCTION;
  }

  return ListingType.NOT_LISTED;
};

export const getListingTypeMapToContract = (listingType?: string) => {
  if (listingType === ListingType.MARKETPLACE) {
    return '0';
  }

  if (listingType === ListingType.AUCTION) {
    return '1';
  }

  if (listingType === ListingType.ENGLISH_AUCTION) {
    return '2';
  }

  return '99';
};

export const displayBalance = (loading: boolean, mybal: string) => {
  if (loading) {
    return '...';
  }

  return mybal;
};

export const getERC721ContractAddressByCategory = (
  category?: string,
  nftCategories?: Nft.NftCategory[],
) => {
  const selected = nftCategories?.find((x) => x.category === category);
  return selected?.contractaddress;
};

export const getERC721ContractCategoryByAddress = (
  contractaddress?: string,
  nftCategories?: Nft.NftCategory[],
) => {
  const selected = nftCategories?.find(
    (x) => x.contractaddress.toLowerCase() === contractaddress?.toLowerCase(),
  );
  return selected?.category;
};

export const getCheckedSumAddress = (address?: string, web3?: Web3) => {
  if (address === '') {
    return '';
  }

  if (address === undefined) {
    return '';
  }

  if (web3 === undefined) {
    return '';
  }

  return web3.utils.toChecksumAddress(address);
};

export const getNftType = (contentType: string): NftType | undefined => {
  if (contentType?.indexOf('image') >= 0) {
    return NftType.Image;
  } else if (contentType?.indexOf('audio') >= 0) {
    return NftType.Audio;
  } else if (contentType?.indexOf('video') >= 0) {
    return NftType.Video;
  } else {
    return;
  }
};

// converts URLs in the form of 'ipfs://cid/filepath' into https://ipfs.io/ipfs/cid/filepath'
// if a 'https://...' or 'http://' url is the input, it will simply return the input
// throws an Error otherwise
export const convertIPFSToHTTPS = (imageUrl: string) => {
  if (imageUrl === undefined) {
    return '/assets/images/preloader.png';
  }

  if (imageUrl.startsWith('https://') || imageUrl.startsWith('http://')) {
    return imageUrl;
  }

  if (imageUrl.startsWith('ipfs://')) {
    // const baseLink = 'https://ipfs.io/ipfs/'; //'https://ipfs.moralis.io:2053/ipfs/'; //
    const baseLink = 'https://toppy.mypinata.cloud/ipfs/';
    const [cid, ...filePathComponents] = imageUrl.substring(7).split('/');
    const filePath = filePathComponents.join('/');
    //const filepathtemp = encodeURIComponent(filePath)
    const final = `${baseLink}${cid}/${filePath}`;
    return final;
  }

  return '/assets/images/preloader.png';
};

export const convertAmountIntoUSD = (
  amount: number,
  priceBNB: number,
  priceXWIN: number,
  paymentToken: string = bnbaddress,
) => {
  if (paymentToken.toLowerCase() === bnbaddress.toLowerCase()) {
    return amount * priceBNB;
  } else if (paymentToken.toLowerCase() === xwinaddress.toLowerCase()) {
    return amount * priceXWIN;
  } else if (paymentToken.toLowerCase() === usdtaddress.toLowerCase()) {
    return amount * 1;
  } else {
    return 0;
  }
};

export const getPriceInUSD = (prices: any, amount: string, tokenPayment: string) => {
  if (amount === undefined) {
    return '0';
  }

  if (tokenPayment.toLowerCase() === bnbaddress.toLowerCase()) {
    return Number(Number(prices.bnb) * Number(amount)).toFixed(2);
  }

  if (tokenPayment.toLowerCase() === xwinaddress.toLowerCase()) {
    return Number(Number(prices.xwin) * Number(amount)).toFixed(2);
  }

  if (tokenPayment.toLowerCase() === usdtaddress.toLowerCase()) {
    return Number(amount).toFixed(2);
  }

  return '0';
};

export const availableNetwork = {
  bscMainnet: {
    chainId: `0x${Number(56).toString(16)}`,
    chainName: 'BSC',
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [
      'https://bsc-dataseed1.binance.org',
      'https://bsc-dataseed2.binance.org',
      'https://bsc-dataseed3.binance.org',
      'https://bsc-dataseed4.binance.org',
      'https://bsc-dataseed1.defibit.io',
      'https://bsc-dataseed2.defibit.io',
      'https://bsc-dataseed3.defibit.io',
      'https://bsc-dataseed4.defibit.io',
      'https://bsc-dataseed1.ninicoin.io',
      'https://bsc-dataseed2.ninicoin.io',
      'https://bsc-dataseed3.ninicoin.io',
      'https://bsc-dataseed4.ninicoin.io',
      'wss://bsc-ws-node.nariox.org',
    ],
    blockExplorerUrls: ['https://bscscan.com'],
  },
  bscTestnet: {
    chainId: `0x${Number(97).toString(16)}`,
    chainName: 'BSC Testnet',
    nativeCurrency: {
      name: 'Binance Chain Native Token',
      symbol: 'BNB',
      decimals: 18,
    },
    rpcUrls: [
      'https://data-seed-prebsc-1-s1.binance.org:8545',
      'https://data-seed-prebsc-2-s1.binance.org:8545',
      'https://data-seed-prebsc-1-s2.binance.org:8545',
      'https://data-seed-prebsc-2-s2.binance.org:8545',
      'https://data-seed-prebsc-1-s3.binance.org:8545',
      'https://data-seed-prebsc-2-s3.binance.org:8545',
    ],
    blockExplorerUrls: [
      'https://explorer.binance.org/smart-testnet',
      'https://testnet.bscscan.com',
    ],
  },
  polygonMainnet: {
    chainId: `0x${Number(137).toString(16)}`,
    chainName: 'Polygon',
    nativeCurrency: {
      name: 'MATIC Token',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [
      'https://matic-mainnet.chainstacklabs.com',
      'https://rpc-mumbai.maticvigil.com',
      'https://rpc-mainnet.matic.network',
      'https://rpc-mainnet.maticvigil.com',
      'https://rpc-mainnet.matic.quiknode.pro',
      'https://matic-mainnet-full-rpc.bwarelabs.com',
    ],
    blockExplorerUrls: ['https://polygonscan.com'],
  },
  polygonTestnet: {
    chainId: `0x${Number(80001).toString(16)}`,
    chainName: 'Mumbai',
    nativeCurrency: {
      name: 'MATIC Token',
      symbol: 'MATIC',
      decimals: 18,
    },
    rpcUrls: [
      'https://matic-mumbai.chainstacklabs.com',
      'https://rpc-mumbai.matic.today',
      'https://rpc-mumbai.maticvigil.com',
      'https://matic-testnet-archive-rpc.bwarelabs.com',
    ],
    blockExplorerUrls: ['https://mumbai.polygonscan.com'],
  },
};

export const getCountDown = (toDateInUnix) => {
  // const minuteSeconds = 60;
  // const hourSeconds = 3600;
  const daySeconds = 86400;

  const diff = toDateInUnix - Date.now() / 1000;
  const stratTime = Date.now() / 1000;
  const endTime = stratTime + diff;
  const remainingTime = endTime - stratTime;
  const days = Math.ceil(remainingTime / daySeconds);
  // const daysDuration = days * daySeconds;

  const countdown = {
    diff,
    stratTime,
    endTime: stratTime + diff,
    remainingTime: endTime - stratTime,
    days: Math.ceil(remainingTime / daySeconds),
    daysDuration: days * daySeconds,
  };

  return countdown;
};

export const changeToBSCNetwork = async (network, currentProvider) => {
  if (systemConfig.chainId === network) {
    return;
  }

  let params;
  switch (systemConfig.chainId) {
    case 56:
      params = [availableNetwork.bscMainnet];
      break;
    case 137:
      params = [availableNetwork.polygonMainnet];
      break;
    case 80001:
      params = [availableNetwork.polygonTestnet];
      break;
    case 97:
    default: // default to bsc testnet
      params = [availableNetwork.bscTestnet];
      break;
  }

  try {
    if (!window.ethereum) {
      console.log('No crypto wallet found');
      return;
      // throw new Error("No crypto wallet found");
    }
    await window.ethereum.request({
      method: 'wallet_addEthereumChain',
      params,
    });
  } catch (err) {
    console.log(err);
  }
};

export const truncateTitle = (nftTitle) => {
  return nftTitle.length > 18 ? nftTitle.substring(0, 18) + '...' : nftTitle;
};

export const displayTwitterLink = (value) => {
  const arr = value.split('/');
  const id = arr[arr.length - 1];
  return 'https://twitter.com/' + id;
};

export const getNetworkById = (chainId) => {
  if (chainId === 56) return availableNetwork.bscMainnet;
  if (chainId === 97) return availableNetwork.bscTestnet;
  if (chainId === 80001) return availableNetwork.polygonTestnet;
  if (chainId === 137) return availableNetwork.polygonMainnet;

  return availableNetwork.bscMainnet;
};

export const getGasPrice = async (web3: Web3) => {
  
  if (systemConfig.chainId == 56) {
    return {
      gasPrice : web3.utils.toWei('5', 'Gwei').toString()
    };
  } else if (systemConfig.chainId == 137) {
    const gasData = await (await fetch('https://gasstation-mainnet.matic.network/v2')).json()
    return {
      maxFeePerGas: web3.utils.toWei(gasData.fast.maxFee.toFixed(6), 'Gwei').toString(),
      maxPriorityFeePerGas: web3.utils.toWei(gasData.fast.maxPriorityFee.toFixed(6), 'Gwei').toString()
    }
  } else if (systemConfig.chainId == 80001) {
    const gasData = await (await fetch('https://gasstation-mumbai.matic.today/v2')).json()
    return {
      maxFeePerGas: web3.utils.toWei(gasData.fast.maxFee.toFixed(6), 'Gwei').toString(),
      maxPriorityFeePerGas: web3.utils.toWei(gasData.fast.maxPriorityFee.toFixed(6), 'Gwei').toString()
    }
  } else if (systemConfig.chainId == 97) {
    return {
      gasPrice : web3.utils.toWei('10', 'Gwei').toString()
    };
  } else {
    return {
      gasPrice : web3.utils.toWei('5', 'Gwei').toString()
    };
  }
}


export const getPaymentTokenDecimalsByAddress = (tokenPayment: string) => {

  if(tokenPayment === undefined) return 18;
  if(tokenPayment.toLowerCase() === bnbaddress.toLowerCase()) return 18;
  if(tokenPayment.toLowerCase() === xwinaddress.toLowerCase()) return 18;
  if(tokenPayment.toLowerCase() === usdtaddress.toLowerCase() 
    && (systemConfig.chainId === 137 || systemConfig.chainId === 80001)) return 6;
  return 18;
};

export const displayMessage = (error: any, t: any) => {
  if (error.message !== undefined) {
    toast.error(error.message, { autoClose: 10000 });
    if (error.code === 4001) {
      toast.info(t('component:toastmessage.4001'), { delay: 1000, autoClose: 10000 });
    } else if (error.code === 32603) {
      toast.info(t('component:toastmessage.32603'), { delay: 1000, autoClose: 10000 });
    } else if (error.code === -32000) {
      toast.info(t('component:toastmessage.32000'), { delay: 1000, autoClose: 10000 });
    }
  } else {
    toast.error(error, { autoClose: 10000 });
    const idx = error.indexOf('50 blocks');
    if (idx > -1) {
      toast.info(t('component:toastmessage.50blocks'), { delay: 1000, autoClose: 10000 });
    }
  }
};

