// bsc mainnet
const collectionsConfig = {
  collection: [
    {
      symbol: 'TOPPY',
      name: 'xWIN Toppy',
      description:
        'Toppy serves as xWIN mascot in xWIN group. It represent the harmony, peaceful and bringing joyful to the community and the world. xWIN utilize blockchain technology to provide decentralized investment management to the investor. xWIN connect all the digital to the real worlds. In addition, each of the NFT in the collection allows you for the high APR staking capability. Earn xWIN tokens!',
      maxSupply: '500',
      costPerNFT: '25000000000000000000',
      contractaddress: '0xAF6544c1Cc7b05817e4E4136d65d0b43a84B4CC8',
      imageURL:
        'https://toppy-market.s3.ap-northeast-1.amazonaws.com/mysterybox/toppy-layers/preview.gif',
      imageBannerURL:
        'https://toppy-market.s3.ap-northeast-1.amazonaws.com/mysterybox/toppy-layers/preview.png',
      owner: '0x4AB357F27639715AEcF5a9bB4919b2eabc8375a5',
      priceType: '1',
      pid: '1',
      maxPerBuy: 5,
      tokenPayment: '0xd88ca08d8eec1E9E09562213Ae83A7853ebB5d28',
      rarityURL: 'ipfs://QmXMWjqJjXg2Tjo9EgicVLuJrtfGpTqicyVNoV6LxTKTij/toppy-rarity-output.json',
      mysteriousbox: true,
      duration: 12,
      averageScore: 4.3,
      maxScore: 12.368,
      poolendblock: 27061143,
      contentType: 'image/jpeg',
      status: 'soldout',
    },
    {
      symbol: '3DTOPPY',
      name: '3D Toppy Coin',
      description:
        '3D Toppy Coin collections. A special 3D Toppy coin collection is special designed and launched by xWIN Finance. It is limited 100 NFT with few utilities including attractive APR staking as well as exclusive priority invitation for upcoming events in the near future.',
      maxSupply: '100',
      costPerNFT: '1000000000000000000',
      contractaddress: '0x9d83a11c0FE62cF451BDEF37F27103c356BF3AA5',
      imageURL:
        'https://toppy-market.s3.ap-northeast-1.amazonaws.com/mysterybox/toppy-3d-layers/toppy3D-preview.gif',
      imageBannerURL:
        'https://toppy-market.s3.ap-northeast-1.amazonaws.com/mysterybox/toppy-3d-layers/banner.png',
      owner: '0xF662318c2866EfA1286eb654f75FFf26cE41728d',
      priceType: '0',
      pid: '0',
      maxPerBuy: 5,
      tokenPayment: '0x0000000000000000000000000000000000000000',
      rarityURL: 'ipfs://QmSgL9g6LCFPcmyBruUZLDUkHt5hvHeZWjcSBK1uWBSmxa/toppy-rarity-output.json',
      mysteriousbox: true,
      duration: 12,
      averageScore: 3.1,
      maxScore: 5.287462,
      poolendblock: 28089143,
      contentType: 'video/mp4',
      status: 'new',
    },
  ],
};

// // polygon testnet
// const collectionsConfig = {
//   collection: [
//     {
//       symbol: '',
//       name: '',
//       description: '',
//       maxSupply: '',
//       costPerNFT: '',
//       contractaddress: '',
//       imageURL: '',
//       imageBannerURL: '',
//       owner: '',
//       priceType: '',
//       pid: '',
//       maxPerBuy: 0,
//       tokenPayment: '',
//       rarityURL: '',
//       mysteriousbox: false,
//       duration: 0,
//       averageScore: 0,
//       maxScore: 0,
//       poolendblock: 0,
//       contentType: 'image/jpeg',
//       status: 'soldout',
//     },
//   ],
// };

export default collectionsConfig;
