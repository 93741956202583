import { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import UserContext from '../contexts/UserContext';
import { resolvePromise, getTokenSymbol, getPaymentTokenDecimalsByAddress, displayMessage } from '../utils/helper';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../contexts/AppContext';
import NftCardExternal from '../components/NftCardExternal';
import CollectionHeader from '../components/CollectionHeader';
import collectionsConfig from '../configs/collectionsConfig';
import ProgressLoader from '../components/ProgressLoader';
import SpinnerLoader from '../components/SpinnerLoader';
import {
  getTokensExternal,
  revealAllNFT,
  getCollectionProperty,
} from '../utils/mysteriosBoxNFTInteractor';
import { mintMysteriousBoxNFT } from '../utils/mintNFTInteractor';
import { saveImportCustomContract } from '../services/metaService';
import { moralisGetPrice, moralisGetMaticPrice, moralisGetXWINPrice, moralisGetUniqueUserCountByAddress } from '../utils/moralisInteractor';
import {
  approvePaymentToken,
  isTokenApprove,
  convertFromWei,
  convertToWei,
  isEnoughBalance,
} from '../utils/blockchainInteractor';
import confetti from 'canvas-confetti';
import Accordion from '../components/Accordion';
import { moralisXWinAddress, wbnbaddress, toppyMint } from '../constant';
import AttributeSlider from '../components/AttributesSlider';
import systemConfig from '../configs/systemConfig';

const useStyles = makeStyles((theme) => ({
  notchedOutline: {
    borderWidth: '1px',
    borderColor: 'white',
  },
}));

const MysteriousBox = ({ match }) => {
  const classes = useStyles();

  const { t } = useTranslation(['common']);
  const { account, web3 } = useContext(AppContext);
  const userContext = useContext(UserContext);
  const { state } = userContext;
  const [metadata, setMetadata] = useState<Nft.NftMeta[]>([]);
  const [nftAddress, setNftAddress] = useState('');
  const [contentType, setContentType] = useState('');
  const [numberofcopy, setNumberofcopy] = useState(1);
  const [isCardLoading, setIsCardLoading] = useState(false);
  const [prices, setPrices] = useState({
    xwin: 1,
    bnb: 1,
    usdt: 1,
  });
  const [isLoading, setIsLoading] = useState({
    loading: false,
    type: 'page',
    message: '',
  });
  const [totalCost, setTotalCost] = useState(1);
  const [tokenIds, setTokenIds] = useState<string[]>();
  const [configData, setConfigData] = useState({
    name: '',
    description: '',
    imageURL: '',
    imageBannerURL: '',
    tokenPayment: '',
    priceType: '0',
    contractaddress: '',
    maxSupply: '0',
    rarityURL: '',
    pid: '',
    longdesc: '',
    maxPerBuy: 0,
    totalunique: 0,
    // endPeriod : ""
  });
  const [collectionData, setCollectionData] = useState({
    totalSupply: '0',
    costPerNFT: '0',
    totalVolume: '0',
    rarityURI: '',
  });

  const [isApproval, setIsApproval] = useState(false);

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    refreshMeta();
  }, [account, state.user]);

  const init = async () => {
    try {
      setNftAddress(match?.params?.address || '');
      // let tempaddress = match?.params?.address
      const card = collectionsConfig.collection.find(
        (x) => x.contractaddress.toLowerCase() === match?.params?.address.toLowerCase(),
      );

      if (card !== undefined) {
        const xwinPrice = await moralisGetXWINPrice();
        const bnbPrice = (systemConfig.chainId==137 || systemConfig.chainId==80001) ? await moralisGetMaticPrice() : await moralisGetPrice(wbnbaddress) ;
        if (xwinPrice && bnbPrice) {
          setPrices({
            xwin: xwinPrice,
            bnb: bnbPrice,
            usdt: 1,
          });
        }
        const totalunique = await moralisGetUniqueUserCountByAddress(card.contractaddress);

        setConfigData({
          name: card.symbol,
          description: card.name,
          imageURL: card.imageURL,
          imageBannerURL: card.imageBannerURL,
          tokenPayment: card.tokenPayment,
          priceType: card.priceType,
          contractaddress: card.contractaddress,
          maxSupply: card.maxSupply,
          rarityURL: card.rarityURL,
          pid: card.pid,
          longdesc: card.description,
          maxPerBuy: card.maxPerBuy,
          totalunique: totalunique || 0,
          // endPeriod : endPeriod?.toString()
        });

        const decimals = getPaymentTokenDecimalsByAddress(card.tokenPayment);
    
        const collProp = await getCollectionProperty(web3, card.contractaddress);
        const costPerNFT = Number(convertFromWei(collProp?.cost, decimals));
        const totalVolume = Number(collProp?.totalSupply) * costPerNFT;
        setCollectionData({
          totalSupply: collProp?.totalSupply,
          costPerNFT: costPerNFT.toString(),
          totalVolume: totalVolume.toString(),
          rarityURI: collProp?.rarityURI,
        });
        setTotalCost(costPerNFT);
        setContentType(card.contentType);
        await checkIfApprove(card, totalCost);
      }
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const refreshMeta = async () => {
    setIsCardLoading(true);
    const metaData = await getTokensExternal(web3, match?.params?.address, account);
    if (metaData) {
      let temp: string[] = [];
      metaData.forEach((m) => {
        if (!m.isReveal) temp.push(m.tokenID || '');
      });
      setTokenIds(temp);
      setMetadata(metaData || []);
      const card = collectionsConfig.collection.find(
        (x) => x.contractaddress === match?.params?.address,
      );
      await checkIfApprove(card, totalCost);
    }
    setIsCardLoading(false);
  };

  const checkIfApprove = async (card, _totalcost) => {
    if (account !== '' && card?.priceType == '1') {
      const isApp = await isTokenApprove(
        account,
        web3,
        _totalcost.toString(),
        card.tokenPayment,
        toppyMint,
      );
      setIsApproval(isApp);
    }
  };

  const handleRevealAll = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
    }
    try {
      if (tokenIds) {
        setIsLoading({
          loading: true,
          type: 'page',
          message: t('component:reveal-nft'),
        });
        const [, unlistError] = await resolvePromise(
          revealAllNFT(account, web3, configData.contractaddress, tokenIds),
        );

        if (!unlistError) {
          const collection = collectionsConfig.collection.find(
            (collection) => collection.contractaddress === nftAddress,
          );

          const body: Nft.ImportCustomContract = {
            chainId: systemConfig.chainId.toString(),
            contractAddress: nftAddress,
            category: collection?.symbol || '',
          };
          const [importCustomContract, importCustomContractError] = await resolvePromise(
            saveImportCustomContract(body),
          );
          if (importCustomContract) {
            confetti();
            toast.success(t('toast.success'));
            setIsLoading({ loading: false, type: 'page', message: '' });
            setTimeout(async function () {
              await refreshMeta();
            }, 5000);
          }
        }
      }
      return;
    } catch (error) {
      toast.error(t('toast.error-buy-nft'));
      // hideLoader();
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const handleBuyNFT = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }

    if (numberofcopy > configData.maxPerBuy) {
      toast.error(t('component:maxPerBuy', { maxPerBuy: configData.maxPerBuy }));
      return;
    }

    if (Number(collectionData.totalSupply) >= Number(configData.maxSupply)) {
      toast.info(t('component:sold-out'));
      return;
    }

    const totalCost = numberofcopy * parseFloat(collectionData.costPerNFT);
    const validBal = await isEnoughBalance(
      account,
      web3,
      configData.tokenPayment as string,
      totalCost.toString(),
    );
    if (!validBal) {
      toast.error(t('component:not-enough-balance'));
      return;
    }

    try {
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:buying-nft'),
      });
      const [, unlistError] = await resolvePromise(
        mintMysteriousBoxNFT(
          account,
          web3,
          configData.contractaddress,
          totalCost.toString(),
          numberofcopy,
          configData.priceType,
        ),
      );

      if (!unlistError) {
        confetti();
        toast.success(t('toast.success'));

        setTimeout(async function () {
          await refreshMeta();
        }, 5000);
      }else{
        displayMessage(unlistError, t)
      }

      return;
    } catch (error) {
      toast.error(t('toast.error-buy-nft'));
      // hideLoader();
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const handleApprove = async () => {
    if (!account) {
      toast.error(t('common:account.not-sign-in'));
      return;
    }
    try {
      setIsLoading({
        loading: true,
        type: 'page',
        message: t('component:enable-token'),
      });
      const [, unlistError] = await resolvePromise(
        approvePaymentToken(account, web3, configData?.tokenPayment, toppyMint),
      );
      if (!unlistError) {
        setIsApproval(true);
      }else{
        displayMessage(unlistError, t)
      }
    } catch (error) {
      toast.error(t('toast.error-approve-payment-token'));
    } finally {
      setIsLoading({ loading: false, type: 'page', message: '' });
    }
  };

  const handleChange = async (e) => {
    setNumberofcopy(Math.abs(e.target.value));
    const _totalCost = parseFloat(collectionData.costPerNFT) * e.target.value;
    setTotalCost(_totalCost);
    await checkIfApprove(collectionData, _totalCost);
  };

  return (
    <>
      <ProgressLoader
        loading={isLoading.loading}
        type={isLoading.type}
        message={isLoading.message}
      />
      <CollectionHeader card={collectionData} configData={configData} />
      <section className="container no-top">
        <div className="d-flex mt-4 justify-content-center align-content-center">
          <div className="mr-4">
            <h3>{t('How many to buy?')}</h3>
          </div>
          <div className="mr-4">
            <input
              type="number"
              name="mintnumber"
              onChange={handleChange}
              value={numberofcopy}
              className="form-control"
            />
          </div>
          <div className="">
            <button
              className="btn-main"
              onClick={configData?.priceType == '1' && !isApproval ? handleApprove : handleBuyNFT}
            >
              {configData?.priceType == '1' && !isApproval ? t('approve') : t('Buy')}
            </button>
          </div>
        </div>

        <Accordion
          alwaysOpen
          className="mt-4 "
          icon="fa fa-file-image-o"
          title={
            isCardLoading
              ? 'Loading...'
              : metadata.length == 0
              ? t('No NFT')
              : t('My Mysterious NFT')
          }
        >
          <div className="row">
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 offset-xl-0 offset-lg-0 offset-md-0">
              <div>
                <div className="easy-box">
                  <div className="row">
                    <SpinnerLoader loading={isCardLoading} />
                  </div>
                  {metadata.length > 0 && (
                    <div className="row mb-4 justify-content-center align-content-center">
                      <button
                        disabled={tokenIds?.length == 0}
                        className="btn-sub1"
                        onClick={handleRevealAll}
                      >
                        Reveal All
                      </button>
                    </div>
                  )}
                  <div className="row">
                    {account !== '' &&
                      metadata?.map((card) => (
                        <>
                          <NftCardExternal
                            card={card}
                            contentType={contentType}
                            nftContract={configData.contractaddress}
                            refreshMeta={refreshMeta}
                          />
                        </>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Accordion>
        <div className="spacer-40"></div>

        <AttributeSlider configData={configData}></AttributeSlider>
      </section>
    </>
  );
};

export default MysteriousBox;
